import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import Home from './components/home/home';
import About from './components/about/about';
import Pricing from './components/pricing/pricing';
import Faqs from './components/faqs/faqs';
import SwimLessons from './components/swimLessons/swimLessons';
import InfantSwimLessons from './components/swimLessons/swimLessonsComponents/infantSwimLessons';
import KidsSwimLessons from './components/swimLessons/swimLessonsComponents/kidsSwimLessons';
import AdultSwimLessons from './components/swimLessons/swimLessonsComponents/adultSwimLessons';
import PerformersSwimLessons from './components/swimLessons/swimLessonsComponents/leadershipSwimLessons';
import SpecialNeedsSwimLessons from './components/swimLessons/swimLessonsComponents/firstaidSwimLessons';
import DevelopmentSwimLessons from './components/swimLessons/swimLessonsComponents/lifeguardingSwimLessons';
import PoolsafetySwimLessons from './components/swimLessons/swimLessonsComponents/poolsafetySwimLessons';
import MastersSwimLessons from './components/swimLessons/swimLessonsComponents/mastersSwimLessons';
import Navbar from './components/navbar/Navbar';
import Contact from './components/contact/About';
import Footer from './components/footer';
import Gallery from './components/gallery/gallery';


function App() {
  return (
    <>
    <Router>
    <Navbar/>
    {/* <Gallery/> */}
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/pricing' element={<Pricing/>}/>
      <Route path='/faqs' element={<Faqs/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/swim-lessons' element={<SwimLessons/>}/>
      <Route path='/adult-swim-lessons' element={<AdultSwimLessons/>}/>
      <Route path='/kids-swim-lessons' element={<KidsSwimLessons/>}/>
      <Route path='/infant-swim-lessons' element={<InfantSwimLessons/>}/>
      <Route path='/leadership-lessons' element={<PerformersSwimLessons/>}/>
      <Route path='/special-care-lessons' element={<SpecialNeedsSwimLessons/>}/>
      <Route path='/poolsafety-lessons' element={<PoolsafetySwimLessons/>}/>
      <Route path='/lifeguarding-services' element={<DevelopmentSwimLessons/>}/>
      <Route path='/masters-swim-lessons' element={<MastersSwimLessons/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
    </Routes>
    <Footer/>
    </Router>
    </>
  );
}

export default App;
