import one from './kidsAssets/1.png';
import two from './kidsAssets/2.png';
import three from './kidsAssets/3.png';
import four from './kidsAssets/4.png';
import five from './kidsAssets/5.png';
import six from './kidsAssets/n6.png'

const KidsSwimLessons = ()=>{
    return(
        <>
        <div className="members-bg ab">
        <div className="about-1">
                <h3>Private Swimming Lessons for Kids (beginners) - SwimWorld Kenya</h3>
                <h4>SwimWorld Kenya offers kids private swim lessons in your own home or condo pool</h4>
            </div>
        </div>
        <div className='less-main'>
            <h5>
            At this level, the child must be able to swim unassisted and also float on their own. They are well-versed on how to conduct themselves when at the pool
</h5>
<h5 className='blue-bg'>
our activities are:
<li>Learning how to jump in the water and exit the pool safely</li>
<li>they learn how to swim without the noodle and they are introduced to row devices such as kickboards and pool buoys</li>
<li>We teach them how to retrieve objects from the bottom of the pool to the surface</li>
<li>
Diving skills are also introduced at this stage
</li>
</h5>
<h5>At this level, the kids are taught four basic strokes of swimming, i.e backstrokes, breaststrokes, freestyle and butterfly.
<br/>It involves a lot of drills and repetition which helps create muscle memory which helps them swim better. All this are done in a fun way to ensure the child is concetrating throughout the sessions. We incorperate a lot of underwater fun games to keep the child entertained as they learn. </h5>
            {/* <h3>The SwimWorld Kenya Kids Swim Program</h3>
<h4>Ages 4+</h4>
         */}
        {/* <div className='less-items'>
                <div className='less'>
                    <img src={one} alt=''/>
                    <h4>SwimWorld Kenya 1</h4>
                    <ul>
                        <li>Enter & exit shallow water</li>
                        <li>Rhythmic breathing</li>
                        <li>Front & Back floats</li>
                        <li>Roll-over floats & glides (assisted)</li>
                        <li>Retrieve objects</li>
                        <li>Front & back glide</li>
                        <li>Front & back glide with kick</li>
                        <li>Flutter kick drill</li>
                        <li>How to recognize an emergency</li>
                        <li>ID basic pool rules</li>
                       
                    </ul>
                </div>

                <div className='less'>
                    <img src={two} alt=''/>
                    <h4>SwimWorld Kenya 2</h4>
                    <ul>
                        <li>Jump in deep water</li>
                        <li>Rhythmic breathing</li>
                        <li>Jellyfish float</li>
                        <li>Tuck float</li>
                        <li>Tread water</li>
                        <li>Change direction</li>
                        <li>Front & back glide with kick</li>
                        <li>Side glide with kick</li>
                        <li>Whip kick in seated position</li>
                        <li>Basic front crawl</li>
                        <li>Basic back stroke</li>
                        <li>Practice with rescue aids</li>
                        
                    </ul>
                </div>

                <div className='less'>
                    <img src={three} alt=''/>
                    <h4>SwimWorld Kenya 3</h4>
                    <ul>
                        <li>Sitting Dive</li>
                        <li>Kneeling dive</li>
                        <li>Sculling on back</li>
                        <li>Back float in deep water</li>
                        <li>Front somersault</li>
                        <li>Swim underwater</li>
                        <li>Front & Back crawl progressions</li>
                        <li>Dolphin kick w/ aid</li>
                        <li>Whip kick w/ aid</li>
                        <li>Distance swim (50m)</li>
                        <li>HELP & huddle position</li>
                        <li>safe diving rules</li>
                        
                    </ul>
                </div>

                <div className='less'>
                    <img src={four} alt=''/>
                    <h4>SwimWorld Kenya 4</h4>
                    <ul>
                        <li>Standing dive</li>
                        <li>Foot first dive</li>
                        <li>Tread water w/ eggbeater</li>
                        <li>Deep water rhythmic breathing</li>
                        <li>Frontstroke</li>
                        <li>Backstroke</li>
                        <li>Elementary backstroke</li>
                        <li>Side stroke</li>
                        <li>Dolphin kick on back</li>
                        <li>Breaststroke arms w/ breathing</li>
                        <li>Distance swim (100m)</li>
                        <li>Care for choking victim</li>
          
                    </ul>
                </div>

                <div className='less'>
                    <img src={five} alt=''/>
                    <h4>SwimWorld Kenya 5</h4>
                    <ul>
                        <li>Shallow dive in deep water</li>
                        <li>Foot first sculling</li>
                        <li>Flip turns</li>
                        <li>Technique refinements and endurance of all main strokes</li>
                        <li>Breaststroke</li>
                        <li>Butterfly</li>
                        <li>Sprints</li>
                        <li>Endurance swim (200m)</li>
                        <li>Swim while clothed</li>
                        <li>Rescue breathing (adult)</li>
                
                    </ul>
                </div>

                <div className='less'>
                    <img src={six} alt=''/>
                    <h4>SwimWorld Kenya 6</h4>
                    <ul>
                        <li>Surface dive & retrieve object</li>
                        <li>Tread water holding weight above water (30 sec.)</li>
                        <li>Legs only treading water (3min.)</li>
                        <li>Continued technique refinements and endurance of all main strokes</li>
                        <li>Breaststroke & butterfly turns</li>
                        <li>Head up front stroke</li>
                        <li>Head up breast stroke</li>
                        <li>Sprints</li>
                        <li>Distance swim (300m)</li>
                        <li>Swim while holding object</li>
                        <li>Rescue breathing (infant & child)</li>
                   
                    </ul>
                </div>
                
        </div> */}

        {/* <h4 className='left'>Stroke Improvement and Endurance</h4>
                <h5>These private swim lessons are designed for kids and teens who want to improve their technique to achieve a more efficient stroke. SwimWorld Kenya swim instructors will evaluate each student’s strokes and identify problem areas to correct them accordingly. These private swim lessons will also focus on building endurance, enabling students to swim for a longer period of time before fatiguing. This swim program is suitable for kids, teens and adults.</h5>

                <h4 className='left'>Lifesaving Society Swim Lessons</h4>
                <h5>SwimWorld Kenya offers the Lifesaving Society’s six-level Swimmer Program, suitable for children aged 5-12, including beginners and swimmers looking to build on the basics. These private at-home swimming lessons for kids include lots of in-water practice to develop solid swimming skills and strokes, as well as Water Smart education as part of every level.</h5>

                <h4 className='left'>Red Cross Swim Lessons</h4>
                <h5>This 10 level private swim lesson program for children and teens replaces and builds upon the old AquaQuest swim program. It is designed for children ages 6 years and older.
<br/><br/>
Red Cross Swim Kids, taught by SwimWorld Kenya swim instructors, introduces swimming skills and water safety skills in a progressive manner by starting with basic breathing and movements in the water, until stroke proficiency is attained. It also teaches a variety of important water safety and first aid skills.</h5>

                */}
        </div>
        </>
    )
}

export default KidsSwimLessons