import React, {useEffect} from "react";
import './home.css'

import vidbanner from './assets/thumbnail.jpeg';
import gradonsden from './assets/logo-dragonsden.svg';
import forbes from './assets/logo-forbes.svg';
import huffington from './assets/logo-huffington.svg';
import inc from './assets/logo-inc.svg';
import techvibes from './assets/logo-techvibes.svg';
import techglobe from './assets/logo-theglobe.svg';
import why1 from './assets/whychoose-1.svg';
import why2 from './assets/whychoose-2.svg';
import why3 from './assets/whychoose-3.svg';
import quote from './assets/quote.svg';
import joan from './assets/Joanne.png';
import rach from './assets/Rachel.png';
import alys from './assets/Alyssa.png';

import mcan from './assets/members-canadianredcross-1.svg';
import missa from './assets/members-issa.svg';
import mls from './assets/members-lifesaving-society.svg';
import mndpa from './assets/members-ndpa.svg';
import mussa from './assets/members-usssa.svg';
// import logo from './assets/';

const Home = ()=>{
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Swimworld Kenya | Home';
      }, []);

    return (
        <>
        <div className="main">
            <div className="main-a">
                <div className="text-a">
                    <h3>Confidence in the water,<br className="brake"/> confidence for life</h3>
                    <h4>Learning to swim is an essential life skill - no matter what age. SwimWorld Kenya’s team of experienced swim instructors and lifeguards is here to help. Professional private swim lessons in the comfort of your home pool.</h4>
                    {/* <div className="buttons">
                        <h4 className="bt-r">Book Now</h4>
                        <h4 className="bt-b">View our instructors</h4>
                    </div> */}
                </div>

                <div className="video">
                <a href='https://youtu.be/o3ljNLAze-g'><img src={vidbanner} alt=''/></a>
                </div>
            </div>

        </div>

        <div className="feature">
            <h4 className="text-a">SwimWorld Kenya is Featured in...</h4>
            <div className="feat">
                <div className="ff">
                    <img src={gradonsden} alt=''/>
                </div>
                <div className="ff">
                    <img src={forbes} alt=''/>
                </div>
                <div className="ff">
                    <img src={huffington} alt=''/>
                </div>
                <div className="ff">
                    <img src={inc} alt=''/>
                </div>
                <div className="ff">
                    <img src={techglobe} alt=''/>
                </div>
                <div className="ff">
                    <img src={techvibes} alt=''/>
                </div>
            </div>
        </div>

        <div className="pitch">
            <div className="pitch-a">
                <h2>Why <br/>SwimWorld Kenya?</h2>
                <h5>Your pool. Our instructors. Peace of mind.</h5>
                <h4 className="bt-r">Get Started</h4>
            </div>

            <div className="pitch-b">
                <div className="p1">
                    <div className="p1-img">
                        <img src={why1} alt=''/>
                    </div>
                    <div className="p1-txt">
                        <h3>choose your local instructor</h3>
                        <h4>SwimWorld Kenya is the only swim school where you can learn about your instructor’s background and experience - before you book. No strangers at your door!</h4>
                    </div>
                </div>

                <div className="p1">
                    <div className="p1-img">
                        <img src={why2} alt=''/>
                    </div>
                    <div className="p1-txt">
                        <h3>Certified & Experienced Instructors</h3>
                        <h4>

SwimWorld Kenya has carefully screened each swim instructor to ensure they have proper certifications, experience and knowledge to deliver water safety results in a fun and safe way.</h4>
                    </div>
                </div>

                <div className="p1">
                    <div className="p1-img">
                        <img src={why3} alt=''/>
                    </div>
                    <div className="p1-txt">
                        <h3>Results & Custom Lesson Plans</h3>
                        <h4>Our knowledgeable swim instructors customize private swim lesson plans based on each student’s individual needs to ensure the best learning experience possible.</h4>
                    </div>
                </div>
            </div>
        </div>

        <div className="stats">
            <div className="stat">
                <h3>75,000+</h3>
                <h4>swim lessons provided</h4>
            </div>

            <div className="stat">
                <h3>10+ years</h3>
                <h4>proudly in business</h4>
            </div>

            <div className="stat">
                <h3>10,000+</h3>
                <h4>confident swimmers taught</h4>
            </div>
        </div>

        <div className="satpol">
            <div className="sat">
                <h3>Satisfaction Guaranteed Policy</h3>
                <h4>SwimWorld Kenya is so confident you’ll love your swim instructor that if for any reason you aren’t happy with them after the first swim lesson, let us know and we will refund your remaining lessons or completely restart your package free of charge with a new swim instructor!</h4>
            </div>
        </div>

        <div className="testimonials">
            <h3 className="test-h">
                See what our customers have to say
            </h3>
            <div className="testms">
                <div className="testm">
                    <img className="t-img" src={joan} alt=''/>
                    <h4 className="t-name">Joane L.</h4>
                    <img src={quote} alt=''/>
                    <h5 className="t-text">
                        I would recommend SwimWorld Kenya to everyone. With SwimWorld Kenya you are learning everything precisely. With the bios online I knew exactly what experience the instructors had, if they’ve been working with children previously – I knew exactly what I was getting. Without a doubt, my children progressed at triple speed vs. enrolling with the city pool.
                    </h5>
                </div>

                <div className="testm">
                    <img className="t-img" src={rach} alt=''/>
                    <h4 className="t-name">Rachel K.</h4>
                    <img src={quote} alt=''/>
                    <h5 className="t-text">
                       My son loves the instructor and he’s learned so much. She relates really well with my son, always arrives on time and is professional. From the 3rd class he was swimming by himself, floating by himself. We are very happy that we found SwimWorld Kenya.</h5>
                </div>

                <div className="testm">
                    <img className="t-img" src={alys} alt=''/>
                    <h4 className="t-name">Alyssa S.</h4>
                    <img src={quote} alt=''/>
                    <h5 className="t-text">
                      When I called, it was very convenient and easy, SwimWorld Kenya set me up right away with a schedule of lessons, there was no waiting. Olivia is 4 and didn’t know how to swim and she’s swimming on her own now and really confident in the pool. Hallie is now a much stronger swimmer now and her strokes have improved tremendously. We’ve had a really positive experience. My girls love it! </h5>
                </div>
            </div>
            {/* <h4 className="bt-r cntr">View Instructors</h4> */}
        </div>

        <div className="members-bg">
        <div className="feature">
            <h4 className="text-a">We are members of</h4>
            <div className="feat">
                <div className="ff mm">
                    <img src={mcan} alt=''/>
                </div>
                <div className="ff mm">
                    <img src={missa} alt=''/>
                </div>
                <div className="ff mm">
                    <img src={mls} alt=''/>
                </div>
                <div className="ff mm">
                    <img src={mndpa} alt=''/>
                </div>
                <div className="ff mm">
                    <img src={mussa} alt=''/>
                </div>
                {/* <div className="ff">
                    <img src={techvibes} alt=''/>
                </div> */}
            </div>
        </div>
        </div>

        
        </>
    )
}

export default Home;