

const MastersSwimLessons =()=>{
    return(
        <>
         <div className="members-bg ab">
        <div className="about-1">
        <h3>Swimming Lessons for Masters - SwimWorld Kenya</h3>
                <h4>Learn to swim or improve your swimming with at home private Masters swim lessons with SwimWorld Kenya</h4>
                
            </div>
        </div>

        <div className="less-main">
            <h5>
            {/* Whether you’re a beginner adult swimmer looking to learn swimming skills or an advanced swimmer looking to improve your swim stroke or fitness levels, SwimWorld Kenya provides private adult swim lessons tailored to meet your individual needs. Our certified and insured private swim instructors travel to your home or condo pool to teach one-on-one private swim lessons in the comfort of your own pool. */}
            
This is a special package. It caters for those who lost touch with swimming probably due to the inevitable dynamics of life. 
This is specifically for adults who are looking at using their swimming skills for<br/><br/> fitness, <br/>perfecting their technique, and <br/>also refreshing skills that they might have lost over time. 
            
            </h5>
         {/*    <h3>The SwimWorld Kenya Adult Swim Program</h3>
            <h4>Suitable for all levels</h4>
            <h5>
            Our certified and insured swim instructors are equipped to teach swimmers of all backgrounds, from first-timers to competitive swimmers and everyone in between! During your first private adult swim lesson, your local SwimWorld Kenya swim instructor will do an assessment and discuss your swimming goals. This helps them determine the best direction for your private swim lessons to ensure your experience is successful and enjoyable.
            <br/><br/>
            We integrate private swimming instruction with water safety to develop your comfort and safety in and around water and tailor lessons depending on your skill level. Here is a look at the different adult swim lesson levels we offer.
            </h5>

            <h4 className="left">
                Beginner Level
            </h4>
            <h5>
            The beginner level of The SwimWorld Kenya Adult Swim Program is perfect for those just beginning their swimming journey. Our friendly and experienced swim instructors can help you overcome your fear of swimming and get comfortable in the water. Skills learned at the beginner stage include:
<br/><br/>
<li>breathing and buoyancy techniques</li>
<li>floating</li>
<li>gliding, kicking, and other skills necessary to prepare for learning full swim strokes</li>

<br/><br/>

Within 10-12 lessons, beginner adult swimmers are often ready to move on to intermediate or advanced levels.
            </h5>
            <h4 className="left">Intermediate Level</h4>
            <h5>
            
The SwimWorld Kenya Adult Swim Program’s Intermediate Level is designed to instill the proper swimming techniques needed to develop long-lasting swimming abilities. Skills learned at the intermediate level include:
<br/><br/>
<li>treading water</li>
<li>front stroke</li>
<li>backstroke</li>
<li>building stroke stamina</li>
            </h5>
            <h4 className="left">Advanced</h4>
            <h5>The Advanced Level of The SwimWorld Kenya Adult Swim Program involves developing more advanced swimming techniques and refining main strokes to build stamina and efficiency. Skills learned at the advanced level include:
</h5>
<br/><br/>
<li>Butterfly stroke</li>
<li>Flip Turns</li>
<li>Diving</li>
<li>Stroke refinement</li>
<li>Proper breathing techniques</li>
<h4 className="left">Specialized Training for Competitions, Triathlons and Fitness</h4>
<h5>SwimWorld Kenya also offers private swim training for swimmers who want to work on building endurance and stamina in the pool. Our swim instructors are also experienced in providing triathlon swim training for swimmers participating in triathlons and swimming competitions.</h5>


 */}


        </div>
        </>
    )
}

export default MastersSwimLessons;