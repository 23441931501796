

const PerformersSwimLessons = ()=>{
    return(
        <>
        <div className="members-bg ab">
        <div className="about-1">
        <h3>Performers swimming Courses - AquaMobile Swim School</h3>
                {/* <h4>Including Bronze Medallion, Bronze Cross and Bronze Star Courses</h4> */}
                
            </div>
        </div>
        <div className="less-main">
            <h5>
            At this level, a swimmer has made a decision to be a competitor. <br/>
            They are well versed in swimming experience and workouts, they understand the terms used in international training and competition
<br/>They are ready to attend the available local and international meets and are legible to compete for country
The level of training is very complex and tough
<br/>They will also be required to supplement their water training with gym and heavy land training</h5>
            {/* <h3>
                Canadian Swim Patrol Program
            </h3>
            <h4>ages 8 - 12 years</h4>

            <h5> The Canadian Swim Patrol program is the gateway to lifeguarding certification for 8-12-year-olds who are ready to go beyond regular swim lessons.
            <br/><br/>
            Swim Patrol develops swimming strength and efficiency with emphasis on Water Smart® behaviour. Skill drills enhance capability in the water, good physical conditioning and lifesaving judgment. It has 3 levels – Rookie, Ranger & Star — and content is organized into the following groups

            <br/><br/>
            <li>Water Proficiency</li>  
            <li>First Aid</li>
            <li>Rescue</li>
            <br/><br/>
            AquaMobile Swim School offers the Lifesaving Society’s Rookie, Ranger & Star Patrol levels through private at-home swim lessons or in small group lesson environments to help student learn skills faster and retain more information.
            </h5>

            <h3>Bronze Star Course</h3>
            <h5>Bronze Star training provides excellent preparation for success in the Bronze Medallion course.
            <br/><br/>
            With AquaMobile private swim lessons for Bronze Star, participants develop problem-solving and decision-making skills individually and in partners. Students will also learn CPR and develop the lifesaving skills needed to be their own lifeguard.
            </h5>

            <h3>Bronze Medallion Course with CPR-B & Emergency First Aid</h3>
            <h4>ages 13+ or those with Bronze Star</h4>

            <h5>
                The Lifesaving Society’s Bronze Medallion award teaches an understanding of the lifesaving principles embodied in the four components of water-rescue education
            <br/><br/>
            <li>judgment</li>
            <li>knowledge</li>
            <li>skill</li>
            <li>fitness</li>
            <br/><br/>
            The Bronze Medallion Course involves learning tows and carries, and defence methods and releases in preparation for challenging rescues involving conscious and unconscious victims. Bronze Medallion private swim lessons also teach lifesavers to develop stroke efficiency and endurance in a timed swim.
            </h5>

            <h3>Bronze Cross Course with CPR-C & Standard First Aid</h3>
            <h4>ages 14+ with Bronze Medallion & Emergency First Aid certification</h4>

            <h5>The Lifesaving Society’s Bronze Cross award is designed for lifesavers who want the challenge of more advanced training, including an introduction to safe supervision in aquatic facilities. Bronze Cross is a prerequisite for all advanced lifeguard training programs including National Lifeguard Service and Instructor certification.
            <br/><br/>
            Bronze Cross private swim lessons with AquaMobile can be taught one-on-one or in small groups and the duration of the program will depend on the number of participants. (Note: Bronze Medallion and Emergency First Aid certifications are required for this course, but they don’t have to be current.)
            </h5>



            <h4>AquaMobile is the #1 choice for Lifesaving Courses and Lifeguard Training</h4>
            <h5>Lifesaving Society certified leadership swim programs allow the student to learn the required skills for lifeguard training and swim instructor training. With AquaMobile, these courses are taught in one-to-one, or smaller group settings by Lifesaving Society certified Instructors and Examiners, allowing students to learn in a focused and efficient manner and receive the attention they need to excel in these rigorous courses.
            <br/><br/>
            With AquaMobile Swim School, participants can book lessons at times convenient for them. Our flexible scheduling allows participants to complete their training at a pace that works for them, whether that’s spread out over several weeks or condensed into a crash course format.
            <br/><br/>
            Pricing for the courses depends on the number of participants. Please call 1-888-950-7946 or email us at info@aquamobileswim.com with the # of participants, postal code, and leadership program you’re interested in for accurate pricing.</h5> */}
        </div>
        </>
    )
}

export default PerformersSwimLessons;