import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import './lessons.css';

import l1 from './assets/l1.png';
import l2 from './assets/l2.png';
import l3 from './assets/l3.png';
import l4 from './assets/l4.png';
import l5 from './assets/l5.png';
import l6 from './assets/l6.png';
import l7 from './assets/l7.png';


const SwimLessons = ()=>{
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Swimworld Kenya | Swim Lessons';
      }, []);

    return(
        <>
        <div className="members-bg ab">
        <div className="about-1">
                <h3>SwimWorld Kenya Private Swimming Lessons</h3>
                <h4>SwimWorld Kenya offers a variety of private swim programs to suit all ages and skill levels</h4>
            </div>
        </div>
        <div className="lessons">
            <h2>The SwimWorld Kenya Method</h2>
            <h3>Safety - Results - Confidence</h3>

            <h5><span className="bold">The SwimWorld Kenya Method</span> has been developed using nearly 20 years of swim coaching experience to help every student <span className="bold">build confidence through sport</span>. Our unique method is centered around three clear principles: Safety, Results, and Confidence.
            <br/><br/>
            Our framework ensures that all our students get the results they need to stay safe in the water and develop confidence that permeates all aspects of their life, both in and out of the pool.
            <br/><br/>
            We are focused on building a skill foundation that lasts a lifetime, regardless of age. As part of the SwimWorld Kenya Method, all students begin by building a solid foundation of swim basics, enabling them to confidently float, kick and swim to the pool's edge. For younger swimmers, we use songs and games to develop critical skills and nurture a love of water.
            <br/><br/>
            A key element of the SwimWorld Kenya Method involves developing fundamental skills rather than just relying on contextual memory recall to stay safe. Once our instructors are sure you (or your loved ones) are confident in the water, we move on to developing and refining each stroke and building technique.
            <br/><br/>
            Each lesson is tailored to the student’s learning style and ability, and as new milestones are unlocked, their confidence grows. With one-to-one teaching by our experienced instructors, you have the support and attention needed to flourish.</h5>

            <h2>SwimWorld Kenya Offerings:</h2>
        </div>
        <div className="lesson-items">
            <div className="item">
                <img src={l1} alt=''/>
                <h4 className="it-header">
                Private Swimming Lessons for Infants & Toddlers (Baby Splash)

                </h4>
                <h4 className="it-header2">Ages 6 months - 4 years</h4>
                <h5 className="it-body">This is a toddler level. It entails fun activites that are meant to get young chidren and babies more comfortable being in water</h5>
                <h5 className="it-link"><Link to='/infant-swim-lessons'>Learn More</Link></h5>
            </div>

            <div className="item">
                <img src={l2} alt=''/>
                <h4 className="it-header">
                Beginners
                </h4>
                {/* <h4 className="it-header2">Ages 4 - 17 years</h4> */}
                <h5 className="it-body">At this level, the child must be able to swim unassisted and also float on their own. They are well-versed on how to conduct themselves when at the pool</h5>
                <h5 className="it-link"><Link to="/kids-swim-lessons">Learn More</Link></h5>
            </div>

            <div className="item">
                <img src={l3} alt=''/>
                <h4 className="it-header">
                Mid-level
                </h4>
                {/* <h4 className="it-header2">Ages 18+</h4> */}
                <h5 className="it-body">At this stage, the swimmers have a good understanding of the four strokes of swimming this level is about drills and repetitions that will help perfect their swimming techniques</h5>
                <h5 className="it-link"><Link to='/poolsafety-lessons'>Learn More</Link></h5>
            </div>

            <div className="item">
                <img src={l4} alt=''/>
                <h4 className="it-header">
                Development
                </h4>
                {/* <h4 className="it-header2">All ages</h4> */}
                <h5 className="it-body">At this level, the swimmer has some experience with some competition to determine their strongest strokes and how they are swam effectively. This level is more about gaining strength, endurance and speed</h5>
                <h5 className="it-link"><Link to='/lifeguarding-services'>Learn More</Link></h5>
            </div>

            <div className="item">
                <img src={l5} alt=''/>
                <h4 className="it-header">
                Performers
                </h4>
                {/* <h4 className="it-header2">Ages 8+</h4> */}
                <h5 className="it-body">At this level, a swimmer has made a decision to be a competitor.
They are well versed in swimming experience and workouts, they understand the terms used in international training and competition</h5>
                <h5 className="it-link"><Link to='/leadership-lessons'>Learn More</Link></h5>
            </div>

            <div className="item">
                <img src={l6} alt=''/>
                <h4 className="it-header">
                Special Care Lessons

                </h4>
                {/* <h4 className="it-header2">All Ages</h4> */}
                <h5 className="it-body">We at SwimWorld Kenya realize that there are kids who need special care. We consider that with open hearts and that they can also learn how to swim.</h5>
                <h5 className="it-link"><Link to='/special-care-lessons'>Learn More</Link></h5>
            </div>

            <div className="item">
                <img src={l7} alt=''/>
                <h4 className="it-header">
                Swim Lessons for Adults
                </h4>
                {/* <h4 className="it-header2">Ages 8+</h4> */}
                <h5 className="it-body">We accept adults of all levels i.e. those who are beginners, those who want to improve, and those who want to swim
 for fitness and healthy workouts.</h5>
                <h5 className="it-link"><Link to='/adult-swim-lessons'>Learn More</Link></h5>
            </div>

            <div className="item">
                <img src={l7} alt=''/>
                <h4 className="it-header">
                Masters Swim Lessons
                </h4>
                {/* <h4 className="it-header2">Ages 8+</h4> */}
                <h5 className="it-body">This is specifically for adults who are looking at using their swimming skills for fitness, perfecting their technique, and also refreshing skills that they might have lost over time.</h5>
                <h5 className="it-link"><Link to='/masters-swim-lessons'>Learn More</Link></h5>
            </div>

           
        </div>
        </>
    )
}

export default SwimLessons;