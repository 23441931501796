

const DevelopmentSwimLessons = ()=>{
    return (
        <>
        <div className="members-bg ab">
        <div className="about-1">
        <h3>Development Swimming lessons - SwimWorld Kenya</h3>
                {/* <h4>Hire private lifeguards to guard at your next pool party</h4> */}
                
            </div>
        </div>
        <div className="less-main">
            <h5>
  {/* Private Lifeguards are a must-have addition for pool parties and events to provide security and peace of mind to you and your guests. */}
At this level, the swimmer has some experience with some competition to determine their strongest strokes
 and how they are swam effectively.
This level is more about gaining strength, endurance and speed. This will help them during competition i.e. galas, meets
<br/><br/>here they are able to swim upto 1000 meters in a competition<br/>
their training is more specialized and tasking to the body.<br/><br/>

A very structured training program, sessions begin with warmups, transitioning into main sets and then cool down






            </h5>
          
          <h5 className="blue-bg">
          Activities 
            {/* SwimWorld Kenya offers private lifeguarding services for events of all sizes. Call our office at 1-888-950-7946 or email us at info@SwimWorld Kenyaswim.com for help determining the correct number of lifeguards for your pool party or event based on the number of guests and age of swimmers. */}
<br/><br/>
<li>here we teach them very complex terms used in swimming</li>
<li>we also do a lot of land training/physical out of water work</li>
<li>we teach them how to have a competition mindset i.e. how to gauge their time, dieting and hydration, how to recover from heavy exercises, how to be ready for competition, how to read the strengths and weaknesses of other competitors</li>
<li></li>

          </h5>


<h5>at this level, a swimmer is transitioning into a competitive athlete</h5>

        </div>
        </>
    )
}

export default DevelopmentSwimLessons