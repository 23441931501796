export const faqs = [
    {
        question: 'How frequently should I book private swim lessons for my children?',
        answer: 'Most students will progress at a faster rate with a higher number of swim lessons per week. This would ideally place intensity at two to three swim lessons a week. One swim lesson a week is by no means detrimental to a student’s progress, merely resulting in a slower rate of improvement. For optimal conditions, a routine should be established. Keeping the swim lesson time and day consistent will help them remember and effectively perform the swim skills they have learned from previous swim lessons.'
    },
    {
        question: 'How far in advance should I book my SwimWorld Kenya private swim lessons?',
        answer: 'Book your swim lessons as soon as possible as times tend to fill up quickly once our swim instructors post their availability. If you don’t see availability in your area it could be that all time slots are already filled up, or it might still be too early in the season. Sometimes our instructors work another job and they don’t have their shift schedule making it hard to post ahead of time. But not to worry! The best thing to do is sign up for our Free Matching Service on Step 3 of our registration system. This way, you will be notified as soon as new availability is posted in your area . Furthermore, we will reach out the instructors in the area to bring attention to your request, and to ensure that you can book your swim lessons as soon as possible. Our peak season for swim lessons runs from June through August. While SwimWorld Kenya swim lessons are available year-round, availability may be limited in some areas.'
    },
    {
        question: 'Can I split the cost of the lesson among multiple students or families?',
        answer: 'Of course! If you have a few students and wish to have the instructor teach at the same time, or within the same hour, you can split the cost between those students. For example if you have two kids on different skill levels, you can give each one a private 30 minute lesson while paying for 1 hour. This is especially useful for siblings, family, and friends. That’s the great thing about the flexibility of SwimWorld Kenya At-Home Swim Lessons! Keep in mind that you must keep the lesson to one payment transaction. For prices in your area take a look at our registration system for more information!'
    },
    {
        question: 'What is SwimWorld Kenya’s cancellation policy?',
        answer: 'We understand things happen like a sick child or travel. We offer a very flexible cancellation policy (you can login and cancel for any reason up to 12 hours before a scheduled appointment). The canceled lesson will be available as a credit to reschedule at your convenience. For this reason, SwimWorld Kenya does not offer monetary refunds once a package of lessons has been purchased.'
    },
    {
        question: 'How do the swim packages work?',
        answer: 'We currently offer swim lessons in packages of 5, 10, or 15 lessons. The more lessons you buy, the more you save! When you first purchase your package, you can choose to schedule just one lesson or book your entire package in one go. If you decide to schedule just one lesson, the remainder of your package will sit as credits in your account. When you`re ready to book the rest, simply log into your account before checking out, and your credits will automatically be applied to your order total.'
    },
    {
        question: 'Are SwimWorld Kenya Swim Instructors certified?',
        answer: 'Yes! SwimWorld Kenya Swim Instructors are certified and insured. We use a multi-tiered hiring process that ensures we hire only the best instructors to represent SwimWorld Kenya. Check out our Instructor Bios to learn more about each swim instructor’s personal teaching experience, specialties and certifications. Give us a call at the office if you’d like to chat about instructors and their certifications in your area. Attention Swim Instructors: Think you have what it takes to be an SwimWorld Kenya Swim Instructor? Take a look at our hiring page and sign-up to join the SwimWorld Kenya Family!'
    },
    {
        question: 'How experienced are SwimWorld Kenya’s Swim Instructors?',
        answer: 'We generally look for swim instructor candidates who have 3 or more years experience teaching swimming when we hire, but SwimWorld Kenya Swim Instructors often times have upwards of 10 years experience. It’s not uncommon to find former nationally placed swimmers, EMT’s, ocean lifeguards, or education professionals among our ranks. Instructors have experience teaching swimmers of all ages, abilities, and skill levels! We pride ourselves on the experience each swim instructor brings with them when they come to your home to teach you and yours how to swim -- It’s an SwimWorld Kenya trait we are truly proud of! If you’d like to chat about the experience of certain swim instructors, or would just like to know a little more about the local swim instructors available in your area, give us a call, and we’d be more than happy to assist you.'
    },
    {
        question: 'How old are SwimWorld Kenya’s Swim Instructors?',
        answer: 'SwimWorld Kenya swim instructors are almost ALWAYS older than 20 years of age. We take great care to provide our customers with mature, experienced swim instructors who can truly provide a swim lesson that teaches each child effectively.'
    },
    {
        question: 'What if my child doesn’t click with our swim instructor?',
        answer: 'All of our instructors are carefully selected and matched with your needs! However, if your child, or you do not get along with the instructor we will gladly place you with someone else! Just contact the main office at 1-888-950-7946, and one of our customer service representatives will be happy to assist you.'
    },
    {
        question: 'Can I meet our SwimWorld Kenya Swim Instructor ahead of time?',
        answer: 'Because our swim instructors are very busy, we are unable to set up a meeting time prior to the first lesson. However your chosen swim instructor will contact you once your booking is complete to introduce themselves and give you an opportunity to ask any questions or give any specific information about the students. Specific SwimWorld Kenya Swim Instructor and Lifeguard Bios & availability can be found through our registration page. Here you can read all about the instructors experience, their qualifications, and even their aquatic specialties. If you’d like to take a look at some general Swim Instructor Bios take a look at our Instructor page.'
    }
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // },
    // {
    //     question: '',
    //     answer: ''
    // }
    // {
    //     question: 'How do I book the remaining lessons in my package?',
    //     answer: 'If you have lesson credits remaining, log in to your account before beginning the booking process as normal. During the "choose package" stage, you can opt to use your remaining credits, which will be automatically applied to your total at checkout. Log in to your account and select "My Orders" from the side menu to view your remaining credits.'
    // }

]