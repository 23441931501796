import React, {useEffect} from "react";
import './pricing.css';

import pp1 from './assets/programs-2.png';
import pp2 from './assets/programs-4.png';
import pp3 from './assets/programs-7.png';
import pp4 from './assets/programs-3.png';
import { Link } from "react-router-dom";


const Pricing = () =>{
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Swimworld Kenya | Pricing';
      }, []);

    return (
        <>
        <div className="members-bg ab">
        <div className="about-1">
                <h3>Prices - SwimWorld Kenya Private Swim Lessons</h3>
                <h4>Take a look at our private swim lessons & service prices below!</h4>
            </div>
        </div>
        <div className="prices">
            <div className="price">
                <div className="p-icon">
                    <img src={pp1} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Baby Splash </h4>
                    <h5 className="p-body">Ages 6 months - 3 yrs </h5>
                </div>
                <div className="p-p">
                    <h5>From Kshs. 13,000/10 lessons</h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/infant-swim-lessons'>Learn More</Link></h5> 
                </div>
            </div>

            <div className="price">
                <div className="p-icon">
                    <img src={pp2} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Beginners </h4>
                    {/* <h5 className="p-body">Ages 6 months - 4 yrs </h5> */}
                </div>
                <div className="p-p">
                    <h5>From Kshs. 13,000/10 lessons</h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/kids-swim-lessons'>Learn More</Link></h5> 
                </div>
            </div>

            <div className="price">
                <div className="p-icon">
                    <img src={pp3} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Mid-level </h4>
                    {/* <h5 className="p-body">Ages 6 months - 4  </h5> */}
                </div>
                <div className="p-p">
                    <h5>From Kshs. 13,000/10 lessons</h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/poolsafety-lessons'>Learn More</Link></h5> 
                </div>
            </div>

            <div className="price">
                <div className="p-icon">
                    <img src={pp4} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Development </h4>
                    {/* <h5 className="p-body">Includes Bronze Star, Bronze Medallion<br/> and Bronze Cross </h5> */}
                </div>
                <div className="p-p">
                    <h5>From Kshs. 14,000/10 lessons</h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/lifeguarding-services'>Learn More</Link></h5> 
                </div>
            </div>

            <div className="price">
                <div className="p-icon">
                    <img src={pp4} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Performers </h4>
                    {/* <h5 className="p-body">Includes Bronze Star, Bronze Medallion<br/> and Bronze Cross </h5> */}
                </div>
                <div className="p-p">
                    <h5>From Kshs. 14,000/10 lessons</h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/leadership-lessons'>Learn More</Link></h5> 
                </div>
            </div>

            <div className="price">
                <div className="p-icon">
                    <img src={pp4} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Special Care Lessons </h4>
                    {/* <h5 className="p-body">Includes Bronze Star, Bronze Medallion<br/> and Bronze Cross </h5> */}
                </div>
                <div className="p-p">
                    <h5>Please contact us for pricing
                    {/* From Kshs. 17,000/10 lessons */}
                    </h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/special-care-lessons'>Learn More</Link></h5> 
                </div>
        </div>
        <div className="price">
                <div className="p-icon">
                    <img src={pp4} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Swim Lessons for Adults</h4>
                </div>
                <div className="p-p">
                    <h5>From Kshs. 16,000/10 lessons</h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/adult-swim-lessons'>Learn More</Link></h5>
                </div>
            </div>
            <div className="price">
                <div className="p-icon">
                    <img src={pp4} alt=''/>
                </div>
                <div className="p-desc">
                    <h4 className="p-head">Masters</h4>
                    <h5 className="p-body">For those returning to<br/> swimming after inactivity </h5>
                </div>
                <div className="p-p">
                    <h5>From Kshs. 13,000/10 lessons</h5>
                </div>
                <div className="p-view">
                    <h5><Link to='/masters-swim-lessons'>Learn More</Link></h5>
                </div>
            </div>
            </div>

        <div className="pp-extras">
            <h3 className="header">
            SwimWorld Kenya — Bringing Confidence Through Sport Since 2011
            </h3>
            <h5 className="p-body">
            SwimWorld Kenya was founded with the goal to help every student we teach build confidence through sport. Using The SwimWorld Kenya Method, our highly trained instructors provide private swim lessons built around your goals to help foster and develop that all-important confidence both in and out of the water.
<br/><br/>
Below are some additional reasons future swimmers around the world choose us.
            </h5>
            <h3 className="header">
            What Advantages Does SwimWorld Kenya Have Over Other At-Home Swim Lesson Providers?
            </h3>

            <h5 className="p-body">
            <span className="bold">Book lessons online immediately</span> — We’re the only at-home swim school that lets you see the instructors in your area AND the times and days they’re available so that you can book instantly. Choose time slots that fit your schedule and lock them in before anyone else takes them. No waiting. No call-backs. Just instant results.
            </h5>
            <h5 className="p-body">
            <span className="bold">Choose your own instructor</span> — Unlike other swim schools, we don’t mass email our instructors to fill bookings; we put the decision in your hands! Browse your local, qualified instructors' full bios, certifications, and experience to find the perfect match for your needs. (Of course, if you need help narrowing down your choices, we’re happy to help!)
            </h5>
            <h5 className="p-body">
            <span className="bold">Get notified of new availability</span> — If you don’t see availability to fit your schedule, we’ll notify you as soon as it becomes available. We understand sometimes you can’t wait (especially when the sun is shining), so you’re free to look at other swim schools, and we’ll automatically notify you once new times/dates are added, or an instructor becomes available.
            </h5>
            <h5 className="p-body">
            <span className="bold">Flexible cancellation policy</span> — We understand things happen like a sick child or travel. We offer a very flexible cancellation policy (you can login and cancel for any reason up to 12 hours before a scheduled appointment). The canceled lesson will be available as a credit to reschedule at your convenience.
            </h5>
            <h5 className="p-body">
            <span className="bold">Benefit from peace of mind insurance</span> — Safety is of the utmost importance to us. That’s why we’re backed by a multi-million dollar insurance policy so you can feel confident in the case of an emergency.
            </h5>
            <h5 className="p-body">
            <span className="bold">We’re run by a team of Swim Instructors</span> — That’s right, the SwimWorld Kenya team is made up of swim instructors and former competitive swimmers. We feel this is the best way to answer any questions about swimming and water safety. If you need help deciding which instructor is the best fit for your needs or a suggestion on lesson frequency based on your goals, our dedicated customer service reps are the people to ask!
            </h5>
            <h5 className="p-body">
            <span className="bold">The SwimWorld Kenya Method</span> — Last but not least, The SwimWorld Kenya Method and its core principles of safety, confidence, and results form the basis of all our swimming lessons. Tailored to each student’s unique learning style and ability, SwimWorld Kenya private swim lessons offer high-quality instruction in your pool on your schedule.
            </h5>
        </div>
        </>
    )
}

export default Pricing;