

const SpecialNeedsSwimLessons = ()=>{
    return (
        <>
        <div className="members-bg ab">
        <div className="about-1">
                <h3>Special Care Lessons - SwimWorld Kenya</h3>
                <h4>Swimming lessons for children with special needs</h4>           
            </div>
        </div>
        <div className="less-main">
            <h5>
            {/* Learn First Aid & CPR lifesaving skills in easy-to-follow steps at a location of your choice, including home, office, or other facilities. With the First Aid & CPR Class from SwimWorld Kenya you will learn how to act on, prevent, and manage life’s emergencies. */}
            
            We at SwimWorld Kenya realize that there are kids who need special care. We consider that with open hearts and that they can also learn how to swim. The coaches are well trained and adequately prepared to effectively train kids with special needs
we work with kids who have all challenges and need the extra care and concern to learn swimming.<br/><br/>
Swimming is fun and we believe that all children should enjoy the fun and games that the world has to offer.
We train children with mental, physical, emotional and development challenges i.e <br/><br/>Autism <br/>Down's Syndrome<br/> Asperegers<br/> A.D.D<br/> hyper activity and other conditions.<br/><br/>
We welcome all with open arms and with the right mindset to ensure they all learn and enjoy.
Our coaches are well trained and understand their scope on training children who need special care. Time, patience and understanding will help the coaches ensure that all kids get to experience the fun of being in the water.

            
            </h5>
            {/* <h3>The SwimWorld Kenya First Aid & CPR Course</h3>

            <h5 className="blue-bg">
            The SwimWorld Kenya First Aid & CPR Course provides comprehensive training covering all aspects of First Aid and CPR, including the treatment of:
            <br/><br/>
            <li>Spinal Injuries</li>
            <li>Heat or cold injuries</li>
            <li>Bone and joint injuries</li>
            <li>Abdominal and chest injuries</li>
            <li>Burns</li>
            <li>Medical emergencies</li>
            <li>and CPR for infant, children, and adults</li>
            </h5>

            <h5>
            <br/><br/>
            Our First Aid & CPR classes can be done through private one-on-one instruction or through group sessions.
<br/><br/>
No pool is required for this course
            </h5> */}
        </div>
        </>
    )
}

export default SpecialNeedsSwimLessons;