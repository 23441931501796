import { Link } from 'react-router-dom';
import './footer.css'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from '../components/home/assets/aquamobileswim.png';
import avatar from './footer_assets/jvouQyNJ_400x400.jpeg';

import lg from '../components/swimLessons/assets/l1.png'
import fb from './footer_assets/FB_icon-icons.com_65484.png';
import tw from './footer_assets/twitter-3_icon-icons.com_50735.png';
import ig from './footer_assets/instagram_logo_icon_173753.png';
import yt from './footer_assets/YOUTUBE_icon-icons.com_65487.png';
import tk from './footer_assets/tiktok_logo_icon_144802.png'

const Footer = ()=>{
    return(
        <>
        <FloatingWhatsApp 
        accountName= 'SwimWorld_Ke' 
        phoneNumber='254757686380'
        avatar={avatar}/>
        <div className="tag">
        
            <div className="tag-txt">
                <h2 className="tag-h">View Instructor Availability and Pricing in Your Area</h2>
                <div className="tag-links">
                    <h4 className="tag-br">book now!</h4>
                    <h4 className="tag-bb">how it works</h4>
                </div>
            </div>
        </div>
        <div className="footer">
            {/* <div className="f1">
                <img src={logo} alt='' />
            </div> */}
            <div className="f2">
                <h3>Home</h3>
                <h4><Link to='/swim-lessons'>Swim Lessons</Link></h4>
                <h4><Link to='/pricing'>Pricing</Link></h4>
                {/* <h4><Link to='/contact'>Contact</Link></h4> */}
                {/* <h4>Login</h4>
                <h4>Book now</h4> */}
            </div>

            <div className="f3">
                <h3>About</h3>
                <h4><Link to='/about'>About</Link></h4>
                {/* <h4>Become an Instructor</h4>
                <h4>Partnerships</h4> */}
                <h4><Link to='/contact'>Contact</Link></h4>
            </div>

            <div className="f4">
                <h3>Resources</h3>
                <h4><Link to='/about'>How it Works</Link></h4>
                {/* <h4>Testimonials</h4> */}
                <h4><Link to='/faqs'>FAQs</Link></h4>
                {/* <h4>Satisfaction Guaranteed Policy</h4> */}
                <div className='social-icons'>
                    <div>
                      <a href='https://www.facebook.com/profile.php?id=100093631631063&mibextid=ZbWKwL'> <img src={fb} alt=''/></a> 
                    </div>
                    
                    <div>
                      <a href='https://twitter.com/SwimWorldKe'> <img src={tw} alt=''/></a> 
                    </div>

                    <div>
                      <a href='https://youtube.com/@Swimworldke'> <img src={yt} alt=''/></a> 
                    </div>

                    <div>
                      <a href='https://www.tiktok.com/@swimworldke?_t=8dLtliV8iWc&_r=1'> <img src={tk} alt=''/></a> 
                    </div>

                    <div>
                      <a href='https://instagram.com/swimworld_ke_kids?igshid=MzNlNGNkZWQ4Mg=='> <img src={ig} alt=''/></a> 
                    </div>

                </div>
            </div>
            
        </div>
        {/* <div className='socials'>
                <img src={lg} alt=''/>
                <img src={lg} alt=''/>
            </div> */}
        </>
    )
}

export default Footer;