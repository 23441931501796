

const PoolsafetySwimLessons = ()=>{
    return(
        <>
        <div className="members-bg ab">
        <div className="about-1">
                <h3>Mid-level swim lessons - SwimWorld Kenya</h3>
                {/* <h4>Learn how to maintain a safe pool environment and what to do in emergency situations</h4>            */}
            </div>
        </div>
        <div className="less-main">
            <h5>
At this stage, the swimmers have a good understanding of the four strokes of swimming
this level is about drills and repetitions that will help perfect their swimming techniques, i.e <br/><br/>
proper breathing while swimming<br/> good arm action<br/> proper kicking<br/> swimming longer distances comfortably

            </h5>
        {/* <h3>The SwimWorld Kenya Pool Safety Course</h3> */}
<h5 className="blue-bg">
Our activities
<br/><br/>
<li>teaching proper use of equipment i.e kickboards, pool bouys e.t.c</li>
<li>perfecting technique for easy swimming</li>
<li>the intensity is mid level but we teach them to swim more than 25 meters</li>
<li>we introduce competition scenarios i.e. diving/ starting a race/ introducting advantage when swimming in a competition</li>
<li>the goal here is to ensure that they can complete simple tasks that they are given</li>
<li>they are able to swim with zero assistance and very limited supervision.</li>
</h5>
        </div>
        </>
    )
}

export default PoolsafetySwimLessons;