import React, {useEffect} from "react";

import FaqHeader from "./faqHeader";
import Accordion from "./accordion";

const Faqs =()=>{
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Swimworld Kenya | FAQs - frequently asked questions';
      }, []);

    return(
        <>
        <div className="members-bg ab">
        <div className="about-1">
                <h3>Frequently Asked Questions</h3>
                <h4>Here are answers to our FAQs</h4>
            </div>
        </div>
        <div className="container">
            {/* <FaqHeader/> */}
            <Accordion/>
        </div>
        </>
    )
}

export default Faqs;