import { useEffect } from 'react';

import './gallery.css';
// import {ReactVideo} from 'reactjs-media';
import vid1 from './assets/v1.mp4';
import vid2 from './assets/v2.mp4';
import vid3 from './assets/v3.mp4';
import vid4 from './assets/v4.mp4';
import vid5 from './assets/v5.mp4';
import vid6 from './assets/v6.mp4';
import vid7 from './assets/v7.mp4';
import vid8 from './assets/v8.mp4';
import vid9 from './assets/v9.mp4';
import vid10 from './assets/v10.mp4';
import vid11 from './assets/v11.mp4';
import vid12 from './assets/v12.mp4';
import vid13 from './assets/v13.mp4';
import vid14 from './assets/v14.mp4';
import vid15 from './assets/v15.mp4';


const Gallery = ()=>{
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Swimworld Kenya | Lessons Gallery';
      }, []);

    return (
        <>
        <div className="members-bg ab">
            <div className="about-1">
                <h3>Lessons Gallery</h3>
                <h4>Watch our SwimWorld Kenya lessons in action</h4>
            </div>
        </div>
        <div className="about-body gal">
            <div className='media-div'>

              <video
            src={vid1}
            controls
            height="500"
            width="auto"
            // margin="5"
            />

              <video
            src={vid2}
            controls
            height="500"
            width="auto"
            // margin="5"
            />

            <video
            src={vid3}
            controls
            height="500"
            width="auto"
            alt=''
            // margin="5"
            />

            <video
            src={vid4}
            controls
            height="500"
            width="auto"
            // margin="5"
            />

<video src={vid5} controls height="500" width="auto" />
<video src={vid6} controls height="500" width="auto" />
<video src={vid7} controls height="500" width="auto" />
<video src={vid8} controls height="500" width="auto" />
<video src={vid9} controls height="500" width="auto" />
<video src={vid10} controls height="500" width="auto" />
<video src={vid11} controls height="500" width="auto" />
<video src={vid12} controls height="500" width="auto" />
<video src={vid13} controls height="500" width="auto" />
<video src={vid14} controls height="500" width="auto" />
<video src={vid15} controls height="500" width="auto" />
<h5 className='yt-l'><a href='https://youtube.com/@Swimworldke'>View more videos</a></h5>
            </div>
           
            

        </div>
        </>
    )
};

export default Gallery;