import React, {useEffect} from "react";
import './about.css';

const About =()=>{
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Swimworld Kenya | About Us';
      }, []);

    return (
        <>
        <div className="members-bg ab">
            <div className="about-1">
                <h3>About Us</h3>
                <h4>Learn more about how SwimWorld Kenya started and what sets us apart</h4>
            </div>
        </div>
        <div className="about-body">
            <h5>
                SwimWorld Kenya was founded in 2018 by Wena Sialo after he became frustrated at the lack of time and attention he was able to give his students in large, community center swim lessons.
            </h5>
<br/> <h5>
The company provides swim lessons and lifeguarding services for children and adults in Nairobi, Kenya. SwimWorld Kenya has grown to become the largest provider of swim lessons across the 
larger Nairobi area and it's environs, we conduct our swimming lessons at the following locations; <b>Diani Springs Apartments - Diani B CLose, Concorde Hotel - Parklands</b> and <b>Solanika 
    Gardens - Muthangari Road.</b> Each location has been carefully selected to ensure that our swimming lessons are enjoyable, satsifactory and result-oriented.
<br/><br/>

From the founding date to today, the focus is still on providing convenient access to swimming lessons and building confidence through sport for swimmers of all ages.
</h5>
<h3>Why SwimWorld Kenya?</h3>
<h5>
At SwimWorld Kenya, we strive to provide clients with unparalleled levels of service by continually pushing ourselves to be at the forefront of the aquatics industry when it comes to convenience, flexibility, safety, trust, and of course, results.
</h5>

<div className="bg-b">
<h4 className="header padded">Convenience and Flexibility</h4>
<h5 className="padded">SwimWorld Kenya offers clients convenience by providing Swim Instructors who will teach private swim lessons at our selected swimming pools. Clients no longer need to worry about the kids safety at the pool. By having a SwimWorld Kenya swim instructor readily available, we aim to save you time and stress.  SwimWorld Kenya has swim instructors on call during the day, evenings and weekends. Clients are able to choose the number of swim lessons each week, as well as the timing of each swim lesson, to ensure thise are never any conflicts. Our private swim lessons can easily be rescheduled, as long as 24 hours notice is given.</h5>
</div>

<h4 className="header padded">Safety & Trust</h4>
<h5 className="padded">Each SwimWorld Kenya swim instructor has undergone a rigorous training program to earn their Red Cross/Lifesaving Instructor certification and CPR-C and Standard First Aid certifications. Our lifeguards also have their National Lifeguard Service Awards.
<br/><br/>
Many of our swim instructors have been teaching for years, hold advanced instructing qualifications, and have formal training as school teachiss or education professionals. In addition, each SwimWorld Kenya swim instructor and lifeguard has been carefully screened by our management team and has undergone a thorough background check.
</h5><h5>
<div className="bg-b">
<h4 className="header padded">Results</h4>
<h5 className="padded">SwimWorld Kenya’s private swim lessons offer teaching on a one-to-one basis (or in a small group setting if desired), so our clients see results much quicker than with othis traditional swim schools. SwimWorld Kenya students progress through swim levels faster, develop increased confidence in and around water, and improve pool safety awareness, stroke form, strength, coordination, agility, and speed.
<br/><br/>
</h5>
</div>
<h3>The SwimWorld Kenya Team</h3>
Wena continues to take an active role in the company's day-to-day operations to ensure the level of quality and customer satisfaction remains high. The SwimWorld Kenya team comprises a group of people who share his passion for sport and are committed to providing top-quality service to customers.

Our pool of SwimWorld Kenya swim instructors and lifeguards goes through a thorough vetting process to ensure the highest quality teaching is available to customers. Each swim instructor and lifeguard is 18 years or older, has a wealth of experience, and is fully certified.
            </h5>

        
        </div>
        </>
    )
}

export default About;