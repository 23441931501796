import React, {useState, useEffect} from "react";
import './about.css';
import axios from "axios";

import email from './email.svg';
import call from './call.svg';

const Contact = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Swimworld Kenya | Contact';
      }, []);

    const [values, setValues] = useState({
        firstName: '', lastName:'', email:'', phone:'', website:'', projectDescription:''
    });

    // const url='';

    const set = name => {
        return ({ target: {value}}) => {
            setValues(oldValues => ({...oldValues, [name]: value}));
        };
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            await saveForm();
            alert('Form submitted succesfully!')
            setValues({
                firstName: '', lastName:'', email:'', phone:'', website:'https://up-roas.com/', projectDescription:''
            })
        } catch (e) {
            alert(`Submission failed! ${e.message}`)
        }
    }

    const saveForm = async () => {
        // const response = await fetch(url, {
        //     method: 'POST',
        //     body: JSON.stringify(values)
        const reponse = await axios.post('https://frantic-fly-woolens.cyclic.app/item',
        values)
        // });
       }

    return (
        <>

<div className="members-bg ab">
        <div className="about-1">
                <h3>Contact us</h3>
                <h4>We're here to help!</h4>           
            </div>
        </div>

        <div className="main-about">
            <div className="about">

                <div className="ab1">
                    <div className="restrain">
                    <h3 className="title-b">
                        Get in touch
                    </h3>
                    <h5 className="sub">
                    Contact our Team to find out how you <br/>access our services!
                    </h5>

                    <div className="card">
                        <div className="card-image">
                            <img src={email} alt=''/>
                        </div>
                        <div className="card-info">
                            <h5 className="sub"><bold>Email us</bold></h5>
                            <h5 className="sub"><a href='mailto:info@swimworldke.com'>info@swimworldke.com</a></h5>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-image">
                            <img src={call} alt=''/>
                        </div>
                        <div className="card-info">
                            <h5 className="sub"><bold>Call us</bold></h5>
                            <h5 className="sub"><a href='tel:0757686380'>0757686380</a></h5>
                        </div>
                    </div>
                    </div>

                </div>

                <div className="ab2">
                    <form onSubmit={onSubmit}>
                        <div className="form-top">
                            <div className="top-a">

                                <div className="top-element">
                                    <label>First Name</label>
                                    <input
                                    required
                                    placeholder="First name"
                                    value={values.firstName}
                            onChange={set('firstName')}/>
                                </div>

                                <div className="top-element">
                                    <label>Last Name</label>
                                    <input
                                    required
                                    value={values.lastName}
                            onChange={set('lastName')}
                                    placeholder="Last name"/>
                                </div>

                            </div>

                            <div className="top-b">
                            <div className="top-element">
                                    <label>Email</label>
                                    <input
                                    placeholder="Email"
                                    required
                                    value={values.email}
                            onChange={set('email')}
                                    type='email'/>
                                </div>

                                <div className="top-element">
                                    <label>Phone</label>
                                    <input
                                    type='number'
                                    placeholder="0123 456 789"
                                    value={values.phone}
                            onChange={set('phone')}
                                    required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-bottom">
                            <label>Message</label>
                            <textarea
                            placeholder="Message"
                            value={values.projectDescription}
                            onChange={set('projectDescription')}/>
                        </div>
                        <button type='submit' className="about-button">Send Message</button>
                    </form>
                </div>
            </div>
        </div>

        </>
    )
}

export default Contact;
