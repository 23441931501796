
import './less.css';

import seal1 from './infantAssets/seal11.jpeg';
import seal2 from './infantAssets/seal21.jpeg';
import clown from './infantAssets/Clownfish1.jpeg';
import seahorse from './infantAssets/Seahorse-2.jpeg';
import dolphin from './infantAssets/Dolphin-21.jpeg';
import shark from './infantAssets/Shark1.jpeg';

const InfantSwimLessons = ()=>{
    return(
        <>
        <div className="members-bg ab">
        <div className="about-1">
        <h3>Infant Swimming Lessons (Baby splash) - SwimWorld Kenya</h3>
                <h4>SwimWorld Kenya offers infant & preschool private swim lessons in your own home or condo pool</h4>
            </div>
        </div>
        <div className='less-main'>
            <h5>
            This is a toddler level. It entails fun activites that are meant to get young chidren and babies more comfortable being in water
<br/><br/>
</h5>
<h5 className="blue-bg">
our main activities are:
<li>getting children comfortable with being in water</li>
<li>dealing with the fear of water</li>
<li>teaching them how to hold their breath, do bubble in the water and ultimately put their head in the water without chocking</li>
<li>learning how to kick and how to move in the water</li>
<li>we teach survival skills and techniques, getting to safety.Swimming to the wall or ladder and getting out safely</li>
<li>they also learn how to swim short distances with and without floating devices</li>


 </h5>

 <h5>Once the child gets comfortable and can swim short distances unassisted, then we can move to the next level </h5>
            {/* <h3>The SwimWorld Kenya Learn-to-Swim Program</h3>
            <h4>Ages 6 months – 4 years</h4>
            <h5>
            Private swim lessons for babies, toddlers, and preschoolers allow for one-to-one instruction and customized lesson plans catered to your child’s individual needs. SwimWorld Kenya private swim instructors conduct an assessment during the first lesson and build plans from there for a truly personalized experience. Each student will receive a report card and an award to commemorate the completion of each level.
            <br/><br/>
            Below is a breakdown of each of the different swim levels offered by SwimWorld Kenya, and a list of some of the skills your child will develop.
            </h5> */}

            {/* <div className='less-items'>
                <div className='less'>
                    <img src={seal1} alt=''/>
                    <h4>Seal & Pup 1</h4>
                    <ul>
                        <li>Enter & exit water together</li>
                        <li>Getting wet w/ toys</li>
                        <li>Front & Back holds</li>
                        <li>Front & Back floats</li>
                        <li>Songs & Games</li>
                        <li>Blowing bubbles</li>
                        <li>Legs: splashing, kicking</li>
                        <li>Arms: splashing, reaching</li>
                        <li>Front & Back glides</li>
                        <li>Water safety rules</li>
                     
                    </ul>
                </div>

                <div className='less'>
                    <img src={seal2} alt=''/>
                    <h4>Seal & Pup 2</h4>
                    <ul>
                        <li>Assisted jump entry</li>
                        <li>Entry from seated position</li>
                        <li>Roll from front to back</li>
                        <li>Roll from back to front</li>
                        <li>Front & Back floats</li>
                        <li>Songs & Games</li>
                        <li>Submerge mouth, nose, eyes</li>
                        <li>Underwater passes</li>
                        <li>Attempt to open eyes underwater</li>
                        <li>Front & back glides</li>
                        <li>Kicking on front & back</li>
                        <li>Combined arm and leg actions on front & back </li>  
                        
                    </ul>
                </div>

                <div className='less'>
                    <img src={seahorse} alt=''/>
                    <h4>Seahorse</h4>
                    <ul>
                        <li>Enter & exit shallow water</li>
                        <li>Jump into shallow water (assisted)</li>
                        <li>Rhythmic breathing</li>
                        <li>Front & Back floats (assisted)</li>
                        <li>Front & back glide (assisted)</li>
                        <li>Kick on front</li>
                        <li>Kick on back</li>
                        <li>How to recognize an emergency</li>
                        <li>How to call for help   </li>
                   
                    </ul>
                </div>

                <div className='less'>
                    <img src={clown} alt=''/>
                    <h4>Clownfish</h4>
                    <ul>
                        <li>Enter & exit shallow water</li>
                        <li>Jump into shallow water (unassisted)</li>
                        <li>Rhythmic breathing</li>
                        <li>Treading water (assisted)</li>
                        <li>Front & Back floats</li>
                        <li>Roll-over floats (assisted)</li>
                        <li>Retrieve objects</li>
                        <li>Change direction under water</li>
                        <li>Front & back glides</li>
                        <li>Flutter kick drills front & back</li>
                        <li>Alternating arm actions on front & back</li>
                        <li>Stop, look, ask!</li>  
                        <li>ID basic pool rules  </li> 
                        
                    </ul>
                </div>

                <div className='less'>
                    <img src={dolphin} alt=''/>
                    <h4>Dolphin</h4>
                    <ul>
                        <li>Jump into deep water</li>
                        <li>Tread water</li>
                        <li>Front & back floats</li>
                        <li>Roll from front to back</li>
                        <li>Roll from back to front</li>
                        <li>Rhythmic breathing</li>
                        <li>Open eyes under water, retrieve object</li>
                        <li>Front & back glides</li>
                        <li>Side glides</li>
                        <li>Kicking on back & front</li>
                        <li>Combined arm & leg actions</li>
                        <li>How to call for help  </li>  
                       
                    </ul>
                </div>

                <div className='less'>
                    <img src={shark} alt=''/>
                    <h4>Shark</h4>
                    <ul>
                        <li>Jump into deep water</li>
                        <li>Tread water</li>
                        <li>Change direction in deep water</li>
                        <li>Tuck float</li>
                        <li>Rhythmic breathing</li>
                        <li>Open eyes under water, retrieve object</li>
                        <li>Front & back glides w/ kick</li>
                        <li>Side glide w/ kick</li>
                        <li>Front stroke</li>
                        <li>Back stroke</li>
                        <li>Dolphin kick (assisted)</li>
                        <li>Distance swim (10m)</li>
                        <li>Reaching assists </li>  
                        
                    </ul>
                </div>

            </div> */}

            {/* <h4 className='left'>SwimWorld Kenya Baby Swim Lessons</h4>
            <h5>With SwimWorld Kenya, your baby can start their infant swim lessons as early as 6 months old. Private at-home baby swimming lessons are a great way for your child to get accustomed to the water in a safe and comfortable environment. SwimWorld Kenya’s private baby swimming lessons provide a fun and secure way to help your baby learn to swim without the hassle of traveling to a community pool.</h5>

            <h4 className='left'>SwimWorld Kenya Parent & Tot Swim Lessons</h4>
            <h5>SwimWorld Kenya private at-home swimming lessons for parents and toddlers are suitable for students ages 6 months to 3 years old. Our Seal & Pup 1 and Seal & Pup 2 levels involve both you and your child being in the water together. These private toddler swimming lessons consist of water orientation and basic safety activities and are carried out in a fun learning environment. SwimWorld Kenya instructors incorporate songs and games into each lesson to engage your toddler and get them comfortable and excited about being in the water. Many of these games are also great to practice at bathtime between lessons too!</h5>
            
            <h4 className='left'>SwimWorld Kenya Preschool Swim Lessons</h4>
            <h5>SwimWorld Kenya’s private at-home preschool swimming lessons allow young children to continue developing their swimming skills with one-to-one instruction from an experienced private swim instructor. Our Seahorse, Clownfish, Dolphin, and Shark levels are all geared towards children ages 18 months to 5 years. These preschool swimming lessons involve gradually introducing learn-to-swim and water safety skills in an age-appropriate learning environment.</h5>

            <h4 className='left'>Lifesaving Society Swim Lessons</h4>
            <h5>The Lifesaving Society’s official Swim for Life program is available via SwimWorld Kenya’s at-home private swim lessons. The Lifesaving Society’s baby and toddler swimming lessons are designed for children ages 6 months to 5 years.
            <br/><br/>
            The first three levels are Parent & Tot classes for ages 6 months to 3 years, which focus on the importance of play in developing water-positive attitudes and skills. The next five levels ensure that children 3-5 year olds become comfortable in the water and have fun developing a foundation of water skills.</h5>

            <h4 className='left'>Red Cross Swim Lessons</h4>
            <h5>SwimWorld Kenya provides the Red Cross swim programs (for children ages 6 months to 5 years) through private swim lessons in your own home pool, facilitating a better learning environment for your child to learn to swim.
            <br/><br/>
            The first two baby swimming lessons levels, Starfish and Duck, aim to provide water orientation and safety for parents and their infants. The next five levels, Sea Turtle, Salamander, Sunfish, Crocodile and Whale, progressively build swimming skills and age-related safety for toddler swimming lessons. The upper Red Cross preschool swimming lessons levels are aimed at the advanced toddler who is too young for Red Cross Swim Kids, but has developed swim skills beyond most toddler swimming lessons</h5> */}

        </div>
        </>
    )
}

export default InfantSwimLessons;